var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.permissionsData.view_menus
    ? _c(
        "CRow",
        [
          _vm.showTable
            ? _c(
                "CCol",
                { attrs: { col: "12" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardHeader",
                        [
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "card-header-with-button",
                                  attrs: { lg: "4" }
                                },
                                [
                                  _c("CSelect", {
                                    attrs: {
                                      label: "Site",
                                      placeholder: "-- Please Select --",
                                      options: _vm.siteNameDropdown,
                                      value: _vm.selectedSite,
                                      disabled: _vm.userHasSingleSite
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        _vm.selectedSite = $event
                                      },
                                      change: _vm.onChangeSite
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.selectedSite != null
                        ? _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "divider",
                                      attrs: { lg: "4" }
                                    },
                                    [
                                      _c("h4", [_vm._v("Categories")]),
                                      _c(
                                        "CListGroup",
                                        [
                                          _c(
                                            "draggable",
                                            _vm._b(
                                              {
                                                on: {
                                                  start: function($event) {
                                                    _vm.drag = true
                                                  },
                                                  end: function($event) {
                                                    _vm.drag = false
                                                  },
                                                  change: _vm.reorderCategories
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedSiteMenuAndItems,
                                                  callback: function($$v) {
                                                    _vm.selectedSiteMenuAndItems = $$v
                                                  },
                                                  expression:
                                                    "selectedSiteMenuAndItems"
                                                }
                                              },
                                              "draggable",
                                              _vm.dragOptions,
                                              false
                                            ),
                                            [
                                              _c(
                                                "transition-group",
                                                {
                                                  attrs: {
                                                    type: "transition",
                                                    name: !_vm.drag
                                                      ? "flip-list"
                                                      : null
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.selectedSiteMenuAndItems,
                                                  function(item) {
                                                    return _c(
                                                      "CListGroupItem",
                                                      {
                                                        key: item.category,
                                                        staticClass: "category",
                                                        class: {
                                                          isDisabled:
                                                            (_vm.isEditing !=
                                                              item.category &&
                                                              item.category_enabled ==
                                                                "false") ||
                                                            (_vm.isEditing ==
                                                              item.category &&
                                                              _vm.newEnabled ==
                                                                "" &&
                                                              item.category_enabled ==
                                                                "false") ||
                                                            (_vm.isEditing ==
                                                              item.category &&
                                                              _vm.newEnabled !=
                                                                "" &&
                                                              _vm.newEnabled ==
                                                                "false")
                                                        },
                                                        attrs: {
                                                          value: item.category
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onMenuCategoryClick(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "CRow",
                                                          {
                                                            class: {
                                                              isEditing:
                                                                _vm.isEditing ==
                                                                item.category
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "CCol",
                                                              {
                                                                staticStyle: {
                                                                  flex:
                                                                    "0 0 65%"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onMenuCategoryClick(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm.isEditing ===
                                                                item.category
                                                                  ? _c(
                                                                      "CInput",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "focus",
                                                                            rawName:
                                                                              "v-focus"
                                                                          }
                                                                        ],
                                                                        model: {
                                                                          value:
                                                                            _vm.newCategoryName,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.newCategoryName = $$v
                                                                          },
                                                                          expression:
                                                                            "newCategoryName"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _c("div", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.category
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ])
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "CCol",
                                                              {
                                                                staticStyle: {
                                                                  "padding-left":
                                                                    "0px",
                                                                  "padding-right":
                                                                    "0"
                                                                }
                                                              },
                                                              [
                                                                _vm.isEditing ==
                                                                item.category
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hand-cursor",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.onCategoryEdit(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        item.category !=
                                                                          _vm.newCategoryName ||
                                                                        item.category_enabled !=
                                                                          _vm.newEnabled
                                                                          ? _c(
                                                                              "CIcon",
                                                                              {
                                                                                staticClass:
                                                                                  "float-right mt-2 ml-2",
                                                                                attrs: {
                                                                                  name:
                                                                                    "cil-save"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            ),
                                                            _c(
                                                              "CCol",
                                                              {
                                                                staticStyle: {
                                                                  "padding-left":
                                                                    "0px",
                                                                  "padding-right":
                                                                    "0"
                                                                }
                                                              },
                                                              [
                                                                _vm.isEditing !=
                                                                item.category
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hand-cursor",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.newCategoryName =
                                                                              item.category
                                                                            _vm.newEnabled =
                                                                              ""
                                                                            _vm.isEditing =
                                                                              item.category
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            staticClass:
                                                                              "float-right mr-1 hide-until-hover",
                                                                            attrs: {
                                                                              name:
                                                                                "cil-pencil"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hand-cursor",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.onEyeClick(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        (_vm.isEditing !=
                                                                          item.category &&
                                                                          item.category_enabled !=
                                                                            "false") ||
                                                                        (_vm.isEditing ==
                                                                          item.category &&
                                                                          _vm.newEnabled ==
                                                                            "" &&
                                                                          item.category_enabled !=
                                                                            "false") ||
                                                                        (_vm.isEditing ==
                                                                          item.category &&
                                                                          _vm.newEnabled !=
                                                                            "" &&
                                                                          _vm.newEnabled !=
                                                                            "false")
                                                                          ? _c(
                                                                              "CIcon",
                                                                              {
                                                                                staticClass:
                                                                                  "float-right mt-2",
                                                                                attrs: {
                                                                                  name:
                                                                                    "cil-eye"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "CIcon",
                                                                              {
                                                                                staticClass:
                                                                                  "float-right mt-2",
                                                                                attrs: {
                                                                                  name:
                                                                                    "cil-eye-slash"
                                                                                }
                                                                              }
                                                                            )
                                                                      ],
                                                                      1
                                                                    )
                                                              ]
                                                            ),
                                                            _c(
                                                              "CCol",
                                                              {
                                                                staticStyle: {
                                                                  "padding-left":
                                                                    "0px",
                                                                  "padding-right":
                                                                    "0"
                                                                }
                                                              },
                                                              [
                                                                _vm.isEditing ===
                                                                item.category
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hand-cursor",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.isEditing =
                                                                              ""
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            staticClass:
                                                                              "float-right mt-2",
                                                                            attrs: {
                                                                              name:
                                                                                "cil-X"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hand-cursor",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.onCategoryDelete(
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CIcon",
                                                                          {
                                                                            staticClass:
                                                                              "float-right hide-until-hover",
                                                                            attrs: {
                                                                              name:
                                                                                "cil-trash"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CRow",
                                        { staticClass: "pt-3" },
                                        [
                                          _c(
                                            "CCol",
                                            { attrs: { lg: "7" } },
                                            [
                                              _c("CInput", {
                                                attrs: {
                                                  placeholder: "Category Name"
                                                },
                                                model: {
                                                  value: _vm.newCategory,
                                                  callback: function($$v) {
                                                    _vm.newCategory = $$v
                                                  },
                                                  expression: "newCategory"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            { attrs: { lg: "5" } },
                                            [
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: { click: _vm.AddCategory }
                                                },
                                                [_vm._v("Add")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.selectedCategory != null
                                    ? _c(
                                        "CCol",
                                        { attrs: { lg: "8" } },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "float-right",
                                              attrs: { color: "primary" },
                                              on: { click: _vm.AddItems }
                                            },
                                            [_vm._v("Add Item")]
                                          ),
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedCategory.category
                                              )
                                            )
                                          ]),
                                          _vm.selectedCategory.items == 0
                                            ? _c(
                                                "CRow",
                                                {
                                                  staticClass:
                                                    "m-3 rounded p-5 bg-light text-center text-light h5"
                                                },
                                                [
                                                  _c("CCol", [
                                                    _vm._v(
                                                      'Click "Add Item" to add some items to this category.'
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "CRow",
                                                { staticClass: "m-3" },
                                                [_c("CCol")],
                                                1
                                              ),
                                          _c(
                                            "draggable",
                                            _vm._b(
                                              {
                                                on: {
                                                  start: function($event) {
                                                    _vm.drag = true
                                                  },
                                                  end: function($event) {
                                                    _vm.drag = false
                                                  },
                                                  change: _vm.reorderMenuItems
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedCategory.items,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectedCategory,
                                                      "items",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedCategory.items"
                                                }
                                              },
                                              "draggable",
                                              _vm.dragOptions,
                                              false
                                            ),
                                            [
                                              _c(
                                                "transition-group",
                                                {
                                                  attrs: {
                                                    type: "transition",
                                                    name: !_vm.drag
                                                      ? "flip-list"
                                                      : null
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.selectedCategory.items,
                                                  function(item) {
                                                    return _c(
                                                      "CRow",
                                                      {
                                                        key: item.item_number,
                                                        staticClass:
                                                          "menu-item",
                                                        class: {
                                                          "menu-item": true,
                                                          isDisabled:
                                                            item.item_enabled ==
                                                            "false"
                                                        },
                                                        attrs: {
                                                          value:
                                                            item.item_number
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onMenuItemClicked(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "CCol",
                                                          {
                                                            attrs: { lg: "2" }
                                                          },
                                                          [
                                                            _c("CImg", {
                                                              staticStyle: {
                                                                height: "75px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  item.image_url,
                                                                fluid: "",
                                                                thumbnail: ""
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "CCol",
                                                          {
                                                            attrs: { lg: "10" }
                                                          },
                                                          [
                                                            _c(
                                                              "CRow",
                                                              [
                                                                _c("CCol", [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ])
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "CRow",
                                                              [
                                                                _c(
                                                                  "CCol",
                                                                  {
                                                                    attrs: {
                                                                      lg: "4"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Item #" +
                                                                        _vm._s(
                                                                          item.item_number
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "CCol",
                                                                  {
                                                                    attrs: {
                                                                      lg: "4"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.category
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "CCol",
                                                                  {
                                                                    attrs: {
                                                                      lg: "4"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.price_currency
                                                                        ) +
                                                                        _vm._s(
                                                                          item.price
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }